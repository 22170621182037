import React, { useContext, useMemo, useState, Element } from 'react';
import {
  arrayOf,
  bool,
  func,
  oneOf,
  instanceOf,
  oneOfType,
  shape,
  string
} from 'prop-types';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Popover,
  TextField,
  Tile,
  TileGroup,
  Typography
} from '@one-thd/sui-atomic-components';
import { Close } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { filterData } from '../helpers';

export const PopoverFeedback = (
  {
    open,
    anchorEl,
    reasons,
    onClose,
    onSubmit,
    buttonsBaseId,
    title,
    closeButtonAriaLabel,
    textAreaPlaceholder,
    submitCTA,
  }
) => {
  const [feedbackReason, setFeedbackReason] = useState('');
  const [feedbackComment, setFeedbackComment] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const dataToSubmit = useMemo(() => (filterData({
    feedbackReason,
    feedbackComment
  })), [feedbackReason, feedbackComment]);

  const handleSelectReason = (__, selected) => {
    setFeedbackReason(selected);
    setCanSubmit(true);
  };

  const populateReasonOptions = () => {
    return reasons?.map(
      (item) => <Tile variant="text-only" value={item} key={item}>{item}</Tile>);
  };

  const handleChange = (event) => {
    const target = event.target;

    setFeedbackComment(target.value);
  };

  const handleClose = (event) => {
    onClose(event);
  };

  const handleSubmit = () => {
    onSubmit(dataToSubmit);
  };

  return isMobile ? (
    <Drawer open={open} onClose={handleClose} anchor="bottom">
      <DrawerHeader title={title} onClose={handleClose} />
      <DrawerBody>
        <section className="sui-flex sui-flex-col sui-gap-2 sui-mb-4">
          <TileGroup
            value={feedbackReason}
            onChange={handleSelectReason}
            aria-label="Reason options"
          >
            {populateReasonOptions()}
          </TileGroup>
          <TextField placeholder={textAreaPlaceholder} onChange={handleChange} multiline minRows={3} fullWidth />
        </section>
      </DrawerBody>
      <DrawerFooter>
        <Button
          id={`${buttonsBaseId}_user-feedback_submit`}
          onClick={handleSubmit}
          disabled={!canSubmit}
          variant="primary"
          fullWidth
        >
          {submitCTA}
        </Button>
      </DrawerFooter>
    </Drawer>
  ) : (
    <Popover open={open} anchorEl={anchorEl?.current || anchorEl}>
      <header className="sui-flex sui-justify-between sui-items-center sui-gap-2 sui-mb-4">
        <Typography variant="body-base" weight="bold">{title}</Typography>{' '}<IconButton
          icon={Close}
          size="small"
          onClick={handleClose}
          aria-label={closeButtonAriaLabel}
        />
      </header>
      <section className="sui-flex sui-flex-col sui-gap-2 sui-mb-4">
        <TileGroup
          value={feedbackReason}
          onChange={handleSelectReason}
          aria-label="Reason options"
        >
          {populateReasonOptions()}
        </TileGroup>
        <TextField placeholder={textAreaPlaceholder} onChange={handleChange} multiline minRows={3} fullWidth />
      </section>
      <footer className="sui-w-full">
        <Button
          id={`${buttonsBaseId}_user-feedback_submit`}
          onClick={handleSubmit}
          disabled={!canSubmit}
          variant="primary"
          fullWidth
        >
          {submitCTA}
        </Button>
      </footer>
    </Popover>
  );
};

PopoverFeedback.displayName = 'PopoverFeedback';

PopoverFeedback.propTypes = {
  open: bool.isRequired,
  anchorEl: oneOfType([shape({ current: instanceOf(Element) }), func, oneOf([null])]),
  reasons: arrayOf(string).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  buttonsBaseId: string.isRequired,
  title: string,
  closeButtonAriaLabel: string,
  textAreaPlaceholder: string,
  submitCTA: string,
};

PopoverFeedback.defaultProps = {
  anchorEl: null,
  title: 'Why did you select this feedback?',
  closeButtonAriaLabel: 'Close',
  textAreaPlaceholder: 'Add details here',
  submitCTA: 'Submit',
};