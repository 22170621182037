import React, { useContext } from 'react';
import { string, number, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QuestionsAndAnswersMobile } from './QuestionsAndAnswersMobile';
import { QuestionsAndAnswersDesktop } from './QuestionsAndAnswersDesktop';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

// TODO: holding for reference
// ugc = 'https://homedepot.ugc.bazaarvoice.com',
// bazaarVoiceApps = 'https://apps.bazaarvoice.com/deployments/homedepot/main_site/production'

export const QuestionsAndAnswers = ({ itemId, seoPageNumber, enableExperienceProperty }) => {
  const { channel } = useContext(ExperienceContext);

  const RenderedView = channel === 'mobile'
    ? QuestionsAndAnswersMobile
    : QuestionsAndAnswersDesktop;

  return (
    <RenderedView
      itemId={itemId}
      seoPageNumber={seoPageNumber}
      enableExperienceProperty={enableExperienceProperty}
    />
  );
};

QuestionsAndAnswers.displayName = 'QuestionsAndAnswers';

QuestionsAndAnswers.dataModel = dataModel;

QuestionsAndAnswers.propTypes = {
  itemId: string.isRequired,
  seoPageNumber: number,
  enableExperienceProperty: bool
};

QuestionsAndAnswers.defaultProps = {
  seoPageNumber: null,
  enableExperienceProperty: false
};
