import { useContext, useState, useEffect } from 'react';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';

const MAGIC_APRON_URL_PATH = '/magic-apron-bot/retrieve-details';
const MAGIC_APRON_KEY = (
  (typeof window !== 'undefined' && window?.location?.hostname === 'www.homedepot.com')
    ? 'l0N45E9cwic3i8ryyanPvTe6ikXPCfgb'
    : 'NK6oozjkm38fJnaACmDbvhLAVShbY1Iu'
);

export const useMagicApron = (userQuestion, itemId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);

  const { deliveryZip, hosts } = useContext(ExperienceContext);
  const storeId = useStoreId();

  const MAGIC_APRON_URL = hosts.apionline + MAGIC_APRON_URL_PATH + '?key=' + MAGIC_APRON_KEY;

  const fetchMagicApronBotResponse = () => {
    setLoading(true);
    setError(false);
    fetch(MAGIC_APRON_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Agent': 'mgc-prn-bt',
      },
      body: JSON.stringify({
        oms_id: itemId,
        delivery_zip: deliveryZip,
        store_id: storeId,
        conversations: [
          {
            type: 'user',
            content: userQuestion,
          }
        ]
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data?.conversations?.[1]?.content) {
          setError(true);
        } else {
          setResponse(data.conversations[1].content);
        }
      })
      .catch((err) => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!userQuestion) return;
    fetchMagicApronBotResponse();
  }, [userQuestion]);

  return {
    loading,
    error,
    response,
  };
};
